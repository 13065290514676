<template>
  <page>
    <div slot="headerLeft">
      <!-- <el-select v-model="selectType" @change="changeHandler">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select> -->
      <el-form inline>
        <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.sn')"
            v-model.trim="params.sn"
            clearable
            @keyup.enter.native="replaceData"
          />
        </el-form-item>
        <!-- <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.store')"
            v-model.trim="params.store"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item> -->

        <el-form-item :label="$t('activeDevice.state')">
          <el-select clearable v-model="params.onlineStatus">
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('deviceList.customer')">
          <el-select multiple collapse-tags clearable v-model="selectService" filterable>
            <el-option
              v-for="item in serviceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            /> </el-select
        ></el-form-item>
        <el-form-item :label="$t('deviceList.deviceType')">
          <el-select collapse-tags clearable v-model="params.model" filterable>
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- <el-input
        :placeholder="$t('deviceList.peripheralName')"
        v-model.trim="params.peripheralName"
        @keyup.enter.native="replaceData"
        clearable
      />

      <el-select
        :placeholder="$t('deviceList.peripheralType')"
        collapse-tags
        clearable
        v-model="params.peripheralType"
      >
        <el-option
          v-for="item in peripheralTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select> -->
        <el-button size="medium" type="primary" plain @click="replaceData()">{{
          $t('query')
        }}</el-button>

        <el-button size="medium" plain @click="resetHandler">{{
          $t('deviceList.reset')
        }}</el-button>

        <el-button
          size="medium"
          type="danger"
          plain
          @click="delHandler(true)"
          v-if="btnShow && btnShow.delete"
          >{{ $t('delete') }}</el-button
        >
        <el-button
          size="medium"
          v-if="btnShow && btnShow.add"
          type="primary"
          @click="
            visible = true
            currentItem = null
          "
          icon="el-icon-plus"
          >{{ $t('deviceList.bindingDevice') }}</el-button
        >

        <el-button
          size="medium"
          v-if="btnShow && btnShow.add"
          type="primary"
          @click="visible2 = true"
          icon="el-icon-plus"
          >{{ $t('deviceList.bindingBatchDevice') }}</el-button
        >
      </el-form>
    </div>

    <div class="table-wrap">
      <el-table
        ref="table"
        @selection-change="handleSelectionChange"
        :data="tableData"
        border
        row-key="id"
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <el-table-column
          min-width="300"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        >
          <template slot-scope="{ row }">
            <!-- <img style="width:28px;height:28px;margin-right:2px" :src="row.image" /> -->
            <span style="position: relative;top: -3px;">{{ row.sn }}</span>
            <img
              v-if="row.bindingRelationship == 1"
              src="../../../../../../assets/img/bind.png"
              class="bindIcon"
            />
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
          width="150"
        />
        <el-table-column
          show-overflow-tooltip
          prop="onlineStatus"
          :label="$t('activeDevice.state')"
        >
          <template slot-scope="{ row }">
            <span :style="{ color: row.onlineStatus === 0 ? '#31ac6d' : '#F56C6C' }">{{
              row.onlineStatus === 0 ? $t('deviceList.onLine') : $t('deviceList.offLine')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="warehouseOutSpName"
          :label="$t('deviceList.deliveryCustomer')"
          width="150"
        />
        <el-table-column
          show-overflow-tooltip
          width="100"
          prop="spName"
          :label="$t('deviceList.customer')"
        />
        <el-table-column
          show-overflow-tooltip
          width="100"
          prop="store"
          :label="$t('deviceList.store')"
        />
        <el-table-column
          show-overflow-tooltip
          width="180"
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />

        <!-- <el-table-column show-overflow-tooltip prop="onlineStatus" label="状态">
          <template slot-scope="{ row }">
            <span :style="{ color: row.onlineStatus === 0 ? '#31ac6d' : '#F56C6C' }">{{
              row.onlineStatus === 0 ? '在线' : '离线'
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          prop="firmwareVersion"
          :label="$t('deviceList.firmwareVersion')"
          width="150"
        />
        <el-table-column
          show-overflow-tooltip
          width="150"
          prop="totalOnlineTimes"
          :label="$t('deviceList.onlineTimes')"
        />

        <el-table-column show-overflow-tooltip width="160" :label="$t('deviceList.lastOnlineTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.time) }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip width="160" :label="$t('deviceList.createdTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="address"
          show-overflow-tooltip
          width="200"
          :label="$t('deviceList.detailedAddress')"
        />

        <el-table-column fixed="right" width="340" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button v-if="btnShow && btnShow.detail" type="text" @click="detail(row)">{{
                $t('details')
              }}</el-button>
              <el-button type="text" v-if="btnShow && btnShow.edit" @click="editHandler(row)">{{
                $t('edit')
              }}</el-button>
              <el-button
                v-if="btnShow && btnShow.remoteAssistance"
                type="text"
                @click="remoteAssistance(row)"
                >{{ $t('deviceList.remote') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.remoteSdAssistance"
                type="text"
                @click="newWindowOpen(row)"
                >{{ $t('deviceList.sedinittaRemote') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.sedinittaRetrieve"
                type="text"
                @click="retrieveSd(row)"
                >{{ $t('deviceList.sedinittaRetrieve') }}</el-button
              >
              <el-button
                type="text"
                v-if="btnShow && btnShow.delete"
                @click="delHandler(false, row.sn)"
                >{{ $t('delete') }}</el-button
              >
              <el-dropdown trigger="click" @command="commandHandler($event, row)">
                <el-button @click="currentItem = row" type="text">
                  {{ $t('more') }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1">{{ $t('deviceList.closeWifi') }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="2">{{
                    $t('deviceList.closeVirtualKeys')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="3">{{
                    $t('deviceList.openVirtualKeys')
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="4">{{ $t('deviceList.restart') }}</el-dropdown-item>
                  <el-dropdown-item :command="12">{{
                    $t('deviceList.timedRestart')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="5">{{
                    $t('deviceList.restoreFactorySettings')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="6">{{
                    $t('deviceList.setTimeZone')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="7">{{
                    $t('deviceList.setSleep')
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="8">{{ $t('deviceList.shutdown') }}</el-dropdown-item>
                  <!-- <el-dropdown-item :command="13">{{
                    $t('deviceList.timedSwitch')
                  }}</el-dropdown-item> -->
                  <el-dropdown-item :command="9">{{
                    $t('deviceList.transferFiles')
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="15">{{ $t('deviceList.retrieve') }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="10">{{
                    $t('deviceList.screenBrightnessSetting')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="11">{{
                    $t('deviceList.fontSizeSettings')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="13">{{
                    $t('deviceList.openGps')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="row.systemType !== 2" :command="14">{{
                    $t('deviceList.closeGps')
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-device-modal
      @getData="replaceData"
      :serviceList="serviceList"
      :groupList="groupList"
      :modelList="modelList"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
    <add-moredevice-modal @getData="replaceData" :visible.sync="visible2" v-if="visible2" />

    <time-zone-modal
      :currentItem="currentItem"
      :visible.sync="timeZonevisible"
      v-if="timeZonevisible"
    />

    <shut-down-modal
      :currentItem="currentItem"
      :visible.sync="shutdownvisible"
      v-if="shutdownvisible"
    />

    <sleep-modal :currentItem="currentItem" :visible.sync="sleepvisible" v-if="sleepvisible" />

    <light-modal :currentItem="currentItem" :visible.sync="lightvisible" v-if="lightvisible" />

    <font-modal :currentItem="currentItem" :visible.sync="fontvisible" v-if="fontvisible" />

    <remote-modal
      :btnShow="btnShow"
      :currentItem="currentItem"
      :frameUrl="frameUrl"
      :visible.sync="remotevisible"
      v-if="remotevisible"
      @command="commandHandler"
    />

    <file-modal :currentItem="currentItem" :visible.sync="filevisible" v-if="filevisible" />

    <!-- 定时重启 -->
    <ScheduledRestartModal
      :currentItem="currentItem"
      :visible.sync="scheduledRestart"
      :multipleSelection="multipleSelection"
      @getData="replaceData"
      v-if="scheduledRestart"
    />

    <!-- 桑达文件取回 -->
    <RetrieveSdModal
      :currentItem="currentItem"
      :visible.sync="retrieveSdVisible"
      v-if="retrieveSdVisible"
    />

    <el-dialog class="remoteDialog" :title="$t('tips')" :visible.sync="dialogVisible" width="30%">
      <div class="content">
        <i class="el-icon-warning"></i>
        {{ $t('deviceList.isNeedRemote') }}
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="remoteRender(false)">{{
          $t('deviceList.mainScreen')
        }}</el-button>
        <el-button type="primary" @click="remoteRender(true)">{{
          $t('deviceList.secondaryScreen')
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 文件取回弹窗 -->
    <el-dialog
      :title="$t('tips')"
      v-if="retrieveVisible"
      :close-on-click-modal="false"
      :visible.sync="retrieveVisible"
      width="640px"
    >
      <div class="tips">
        <p>{{ $t('deviceList.retrieveTips') }}</p>
        <el-image style="width: 600px;" :src="tips" :preview-src-list="[tips]" />
        <p>
          {{ $t('deviceList.address')
          }}<el-button
            size="mini"
            icon="el-icon-document-copy"
            v-if="retrieveInfo.address"
            @click="copy(retrieveInfo.address)"
            >{{ $t('copy') }}</el-button
          >：{{ retrieveInfo.address || '' }}
        </p>

        <p>
          {{ $t('deviceList.conversation')
          }}<el-button
            size="mini"
            icon="el-icon-document-copy"
            v-if="retrieveInfo.cid"
            @click="copy(retrieveInfo.cid)"
            >{{ $t('copy') }}</el-button
          >：{{ retrieveInfo.cid || '' }}
        </p>
      </div>
      <span slot="footer">
        <el-button @click="retrieveVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="download">{{ $t('download') }}</el-button>
      </span>
    </el-dialog>
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddDeviceModal from '../components/AddDeviceModal'
import AddMoredeviceModal from '../components/AddMoredeviceModal'
import TimeZoneModal from '../components/TimeZoneModal'
import SleepModal from '../components/SleepModal'
import LightModal from '../components/LightModal'
import FontModal from '../components/FontModal'
import ShutDownModal from '../components/ShutDownModal'
import RemoteModal from '../components/RemoteModal'
import RetrieveSdModal from '../components/RetrieveSdModal'
import FileModal from '../components/FileModal'
import tips from '@/assets/img/tips.png'
import ScheduledRestartModal from '../components/ScheduledRestartModal'
import { HOST, BASE_URL, isDev } from '@/lib/constant'
import win from '@/assets/img/win.png'
import android from '@/assets/img/android.png'
export default {
  mixins: [watchParamsGetData],
  components: {
    AddDeviceModal,
    AddMoredeviceModal,
    TimeZoneModal,
    SleepModal,
    ShutDownModal,
    RemoteModal,
    FileModal,
    LightModal,
    FontModal,
    ScheduledRestartModal,
    RetrieveSdModal
  },
  data() {
    return {
      selectType: 1,
      firstIn: true,
      dialogVisible: false,
      loading: false,
      serviceList: [],
      groupList: [],
      modelList: [],
      multipleSelection: [],
      appList: [],
      selectService: [],
      frameUrl: '',
      visible: false,
      visible2: false,
      timeZonevisible: false,
      sleepvisible: false,
      shutdownvisible: false,
      filevisible: false,
      remotevisible: false,
      lightvisible: false,
      fontvisible: false,
      timer: null,
      currentItem: {},
      tableData: [],
      status: [
        {
          label: this.$t('deviceList.onLine'),
          value: 0
        },
        {
          label: this.$t('deviceList.offLine'),
          value: 1
        }
      ],
      peripheralTypeList: [
        {
          label: this.$t('deviceList.printer'),
          value: 'printer'
        },
        {
          label: this.$t('deviceList.scanningDevice'),
          value: 'scanningEquipment'
        },
        {
          label: this.$t('deviceList.camera'),
          value: 'camera'
        }
      ],
      options: [
        {
          label: this.$t('deviceList.sn'),
          value: 1
        },
        {
          label: this.$t('deviceList.store'),
          value: 2
        },
        {
          label: this.$t('deviceList.onLine'),
          value: 3
        },
        {
          label: this.$t('deviceList.customer'),
          value: 4
        },
        {
          label: this.$t('deviceList.peripheralName'),
          value: 5
        },
        {
          label: this.$t('deviceList.peripheralType'),
          value: 6
        }
      ],
      timevisible: false,
      scheduledRestart: false,
      retrieveSdVisible: false,
      retrieveVisible: false,
      retrieveInfo: {},
      tips,
      params: {
        sn: '',
        onlineStatus: '',
        model: ''
      }
    }
  },
  created() {},
  watch: {
    remotevisible(val) {
      if (!val) {
        this.resetData()
      }
    }
  },
  methods: {
    resetHandler() {
      this.params.onlineStatus = undefined
      this.selectApps = []
      this.selectService = []
      this.params.store = undefined
      this.params.sn = undefined
      this.params.peripheralType = undefined
      this.params.peripheralName = undefined
      this.params.model = undefined

      this.getData()
    },
    commandHandler(val, row = null) {
      if (val === 6) {
        this.timeZonevisible = true
        return
      }

      if (val === 8) {
        this.shutdownvisible = true
        return
      }

      if (val === 9) {
        this.filevisible = true
        return
      }

      if (val === 7) {
        this.sleepvisible = true
        return
      }

      if (val === 10) {
        this.lightvisible = true
        return
      }

      if (val === 11) {
        this.fontvisible = true
        return
      }

      if (val === 15) {
        if (row) {
          this.retrieve(row)
        }
        return
      }
      if (val === 12) {
        // 定时重启
        this.scheduledRestart = true
        return
      }
      // if (val === 13) {
      //   // 定时开关机
      //   this.timevisible = true
      //   return
      // }

      let tips = this.$t('deviceList.closeWifi')
      let successTips = this.$t('deviceList.alreadyCloseWifi')
      switch (val) {
        case 1:
          tips = this.$t('deviceList.closeWifi')
          successTips = this.$t('deviceList.alreadyCloseWifi')
          break
        case 2:
          tips = this.$t('deviceList.closeVirtualKeys')
          successTips = this.$t('deviceList.alreadyCloseVirtualKeys')
          break
        case 3:
          tips = this.$t('deviceList.openVirtualKeys')
          successTips = this.$t('deviceList.alreadyOpenVirtualKeys')
          break
        case 4:
          tips = this.$t('deviceList.restart')
          successTips = this.$t('deviceList.restartSuccess')
          break
        case 5:
          tips = this.$t('deviceList.restoreFactorySettings')
          successTips = this.$t('deviceList.restoreFactorySettingsSuccess')
          break
        case 13:
          tips = this.$t('deviceList.openGps')
          successTips = this.$t('succeeded')
          break
        case 14:
          tips = this.$t('deviceList.closeGps')
          successTips = this.$t('succeeded')
          break
      }

      const sn = this.currentItem.sn
      let str = this.$t('deviceList.wantTo')
      this.$confirm(`${str} ${tips}?`, this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          const params = {
            deviceSn: sn,
            type: val
          }

          this.loading = true

          try {
            await this.$api.deviceManagement.publishRemoteAssistance(params)

            this.$message.success(`${successTips}！`)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async getSelectData() {
      let result = await Promise.all([
        this.$api.systemManagement.spList({ pageSize: -1 }),
        this.$api.deviceManagement.groupList({ pageSize: -1 }),
        this.$api.deviceManagement.modelList({ pageSize: -1 })
      ])

      if (result[0].result) {
        this.serviceList = result[0].result.list.map(item => {
          return {
            label: item.name,
            value: item.spId
          }
        })
      }

      if (result[1].result) {
        this.groupList = result[1].result.list.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      }

      if (result[2].result) {
        this.modelList = result[2].result.list.map(item => {
          return {
            label: item.name,
            value: item.id,
            signatureFileName: item.signatureFileName
          }
        })
      }
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    async renderData() {
      try {
        const params = JSON.parse(JSON.stringify(this.params))

        if (this.selectService.length) {
          params.spIds = this.selectService.join(',')
        }

        if (params.store === '') {
          params.store = null
        }

        params.peripheralType = params.peripheralType || undefined

        const res = await this.$api.deviceManagement.deviceList(params)

        if (res.result) {
          this.tableData = ((res.result && res.result.list) || []).map(item => {
            const targetService = this.serviceList.find(_ => _.value === item.spId)
            const targetModel = this.modelList.find(_ => _.value === item.model)

            item.spName = (targetService && targetService.label) || ''
            item.modelName = (targetModel && targetModel.label) || ''
            item.time = item.lastOnlineTime

            item.sysyemType = item.type || 1 //测试

            item.image = item.sysyemType == 1 ? android : win

            if (item.groups && item.groups.length) {
              // item.groupName = item.groups.map(_ => _.name).join('；')
              item.groupName = item.groups[0] && item.groups[0].name
            }
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    detail(row) {
      sessionStorage.setItem('currentRow', JSON.stringify(row))
      this.$router.push(`/deviceManagement/deviceList/deviceListDetail/${row.sn}/${row.model}`)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.sn)
    },
    editHandler(row) {
      console.log(33333)
      this.currentItem = row
      this.visible = true
    },

    openWindow(url, windowName, width, height) {
      /*居中*/
      // var x = parseInt(screen.width / 2.0) - width / 2.0
      // var y = parseInt(screen.height / 2.0) - height / 2.0

      /*全屏*/
      var x = 0
      var y = 0
      var width = screen.width
      var height = screen.height

      var win = null
      var p = 'resizable=1,location=no,scrollbars=no,width='
      p = p + width
      p = p + ',height='
      p = p + height
      p = p + ',left='
      p = p + x
      p = p + ',top='
      p = p + y
      win = window.open(url, windowName, p)
      // win = window.open(url, windowName)
      if (win) {
        this.timer = setInterval(() => {
          if (win.closed) {
            this.resetData()
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    async resetData() {
      try {
        await this.$axios.get(BASE_URL + '/app/api/publish/remoteExit', {
          params: { deviceSn: this.deviceSn }
        })
      } catch (e) {
        console.log(e)
      }
    },

    async remoteRender(deputyScreen) {
      let width = window.innerWidth * 0.8
      let Height = window.innerHeight * 0.8

      const params = {
        deviceSn: this.currentItem.sn,
        spId: this.currentItem.spId
      }

      this.deviceSn = this.currentItem.sn

      this.loading = true

      try {
        const res = await this.$api.deviceManagement.remoteAssistance(params)

        if (res.result) {
          const platform = location.pathname.includes('admin') ? 'admin' : 'service'
          let remote = this.$t('deviceList.remote')
          this.openWindow(
            `http://${HOST}/remote?address=${res.result.address}&cid=${res.result.cid}&sn=${this.currentItem.sn}&platform=${platform}`,
            remote,
            width,
            Height
          )
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async remoteAssistance(item) {
      this.currentItem = item

      if (this.timer) {
        this.$message.warning(this.$t('deviceList.deviceIsRemote'))
        return
      }

      if (item.onlineStatus === 1) {
        this.$message.warning(this.$t('deviceList.deviceIsOffline'))
        return
      }

      if (item.sysyemType == 1) {
        this.$confirm(this.$t('deviceList.isNeedRemote'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
          .then(async () => {
            this.remoteRender(false)
          })
          .catch(() => {})
      } else {
        this.dialogVisible = true
      }
    },
    copy(val = '') {
      this.$utils.copyText(val, this)
    },
    async download() {
      //测试环境 http://138.88.8.112:8888/group1/M00/00/2E/ilgIcGS6TD6AZojtAch_ECliTzg228.rar
      let code = isDev
        ? 'group1/M00/00/2E/ilgIcGS6TD6AZojtAch_ECliTzg228.rar'
        : 'group1/M00/00/0D/wKgABWTS_sWAUIEvAch_ECliTzg361.rar'

      // 默认生产下载地址
      let url = 'https://fdfs.scp.sedsy.com/group1/M00/00/0D/wKgABWTS_sWAUIEvAch_ECliTzg361.rar'

      if (isDev) {
        // let { result = '' } = await this.$api.deviceManagement.getDownLoadUrl(code)
        // url = result
        url = 'http://138.88.8.112:8888/group1/M00/00/2E/ilgIcGS6TD6AZojtAch_ECliTzg228.rar'
      }
      if (url) {
        window.open(url, '_self')
      }
      try {
      } catch (e) {
        console.log(e)
      }
    },
    //文件取回
    async retrieve(item) {
      if (item.onlineStatus === 1) {
        this.$message.warning(this.$t('deviceList.deviceIsOffline'))
        return
      }

      try {
        this.loading = true
        const params = {
          deviceSn: item.sn,
          spId: item.spId
        }
        const res = await this.$api.deviceManagement.retrieve(params)

        if (res.result) {
          this.retrieveInfo = res.result
          this.retrieveVisible = true
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    //桑达文件取回
    async retrieveSd(item) {
      if (item.onlineStatus === 1) {
        this.$message.warning(this.$t('deviceList.deviceIsOffline'))
        return
      }
      try {
        this.loading = true
        const res = await this.$api.common.statisticAnalysis({ deviceSn: item.sn, remoteType: 2 })
        if (res.result) {
          this.currentItem = { ...item, result: res.result }
          this.retrieveSdVisible = true
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    // async resetData() {
    //   try {
    //     const res = await this.$axios.get(BASE_URL + '/app/api/publish/remoteExit', {
    //       params: { deviceSn: this.deviceSn }
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    // async remoteAssistance(item) {
    //   this.currentItem = item

    //   if (item.onlineStatus === 1) {
    //     this.$message.warning('设备已离线')
    //     return
    //   }

    //   this.$confirm('是否要远程协助?', this.$t('tips'), {
    //     confirmButtonText: '确认',
    //     cancelButtonText: '取消'
    //   })
    //     .then(async () => {
    //       const params = {
    //         deviceSn: item.sn,
    //         spId: item.spId
    //       }

    //       this.deviceSn = item.sn

    //       this.loading = true

    //       try {
    //         const res = await this.$api.deviceManagement.remoteAssistance(params)

    //         if (res.result) {
    //           this.frameUrl = `http://${HOST}/remote?address=${res.result.address}&cid=${res.result.cid}`
    //           this.remotevisible = true
    //         }
    //       } catch (e) {
    //         console.log(e)
    //       } finally {
    //         this.loading = false
    //       }
    //     })
    //     .catch(() => {})
    // },
    delHandler(delMore, sn) {
      if (delMore && !this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }
      this.$confirm(
        delMore ? this.$t('deviceList.isDeleteDevice') : this.$t('deviceList.isDeleteThisDevice'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          this.loading = true

          try {
            await this.$api.deviceManagement.deviceDelete(delMore ? this.multipleSelection : [sn])
            this.$message.success(this.$t('deleteSuccess'))
            this.$refs.table && this.$refs.table.clearSelection()
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async newWindowOpen(row) {
      try {
        if (row.onlineStatus === 1) {
          this.$message.warning(this.$t('deviceList.deviceIsOffline'))
          return
        }
        this.loading = true
        const res = await this.$api.common.statisticAnalysis({ deviceSn: row.sn, remoteType: 1 })
        const d = res.result
        let windowName = this.$t('deviceList.remote')
        let width = screen.width
        let height = screen.height
        let p = `resizable=1,location=no,scrollbars=no,width=${width},height=${height},left=0,top=0`
        localStorage.setItem(`remote${row.sn}`, d)
        window.open(`${location.origin}${location.pathname}#/remoteSd?sn=${row.sn}`, windowName, p)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.remoteDialog {
  .content {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .el-icon-warning {
    color: #f78a42;
    font-size: 32px;
    margin-right: 6px;
  }

  ::v-deep .el-dialog {
    margin-top: 40vh !important;
  }

  ::v-deep .el-dialog__body {
    padding: 6px 20px 4px !important;
  }

  ::v-deep .el-dialog__footer {
    text-align: right !important;
  }
}

::v-deep .el-dropdown {
  margin-left: 10px;

  // .el-button--medium {
  //   font-size: 12px;
  // }
}

.iframe-wrap {
  position: fixed;
  height: 100%;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .self-header {
    display: flex;
    justify-content: flex-end;
    background: #dee1e6;
    padding: 6px;
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.bindIcon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
  left: 4px;
}
.tips {
  line-height: 32px;
}
</style>
